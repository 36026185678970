import { UIMatch, useMatches } from "@remix-run/react";

export function useMatchesIndex(
	index: number,
): UIMatch<unknown, unknown> | undefined {
	const matchingRoutes = useMatches();

	// Pastikan index tidak berada di luar jangkauan array
	if (index >= 0 && index < matchingRoutes.length) {
		const route = matchingRoutes[index];
		return route as UIMatch<unknown, unknown> | undefined;
	}

	// Jika indeks tidak valid, kembalikan undefined
	return undefined;
}
