import { type loader as RootLoader } from "#app/root";
import { EmptyState } from "#app/components/ui/empty-state.tsx";
import { Button } from "#app/components/ui/button";
import { Badge } from "#app/components/ui/badge";
import { Input } from "#app/components/ui/input";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuRadioGroup,
  DropdownMenuGroup,
  DropdownMenuRadioItem,
  DropdownMenuSeparator,
  DropdownMenuShortcut,
  DropdownMenuSub,
  DropdownMenuSubContent,
  DropdownMenuSubTrigger,
  DropdownMenuTrigger,
} from "#app/components/ui/dropdown-menu";
import { cn, getInitials } from "#app/utils/misc.tsx";
import { type IconName } from "@/icon-name";
import { Form, NavLink, useFetcher, useNavigate, useRouteLoaderData } from "@remix-run/react";
import React from "react";
import { useTranslation } from "react-i18next";
import { Icon } from "./ui/icon.tsx";

interface NavItemProps {
  to: string;
  label: string;
  iconName?: IconName;
}

const NavItem: React.FC<NavItemProps> = ({ to, iconName, label }) => {
  return (
    <NavLink
      to={to}
      unstable_viewTransition
      className={({ isActive, isPending }) =>
        cn(
          isPending && "cursor-none",
          isActive ? "text-[#9D21E6]" : "text-gray-400",
          "flex flex-col justify-arround items-center"
        )
      }
    >
      {iconName ? (
        <Icon name={iconName} stroke="" className="w-7 h-7" />
      ) : (
        <img src="/static/images/logokedi.svg" alt="Logo Kedi" className="w-7 h-7" />
      )}
      <span className="text-sm font-medium">{label}</span>
    </NavLink>
  );
};

const NavigationMenu = ({ className }: { className: string }) => (
  <div className={className}>
    <NavItem to="/" iconName="home" label="Home" />
    <NavItem to="/dashboard" iconName="assessment" label="Dashboard" />
    <NavItem to="/messages" label="Kelas" />
    <NavItem to="/calendar" iconName="date-range" label="Kalendar" />
    <NavItem to="/market" iconName="storefront" label="Market" />
  </div>
);

const Logo = () => (
  <React.Fragment>
    <NavLink to="/" unstable_viewTransition className="hidden sm:inline relative max-w-[208px]">
      <img
        src="/static/images/logomain.svg"
        style={{ width: 208, height: 50 }}
        width={208}
        height={66}
        className="w-auto h-auto"
        alt="Logo Kedi"
      />
    </NavLink>
    <NavLink
      to="/"
      unstable_viewTransition
      className="inline sm:hidden  max-h-[45px] max-w-[150px]"
    >
      <img
        src="/static/images/logotext.svg"
        width="0"
        height="0"
        sizes="100vw"
        className="pt-2 w-full h-auto"
        alt="Logo Kedi"
      />
    </NavLink>
  </React.Fragment>
);

const SearchMenu = () => {
  return (
    <NavLink to="/search">
      <div className="relative ml-auto flex-1 md:grow-0">
        <svg
          className="absolute left-2.5 top-2.5 h-5 w-5 text-muted-foreground"
          viewBox="0 0 15 15"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M10 6.5C10 8.433 8.433 10 6.5 10C4.567 10 3 8.433 3 6.5C3 4.567 4.567 3 6.5 3C8.433 3 10 4.567 10 6.5ZM9.30884 10.0159C8.53901 10.6318 7.56251 11 6.5 11C4.01472 11 2 8.98528 2 6.5C2 4.01472 4.01472 2 6.5 2C8.98528 2 11 4.01472 11 6.5C11 7.56251 10.6318 8.53901 10.0159 9.30884L12.8536 12.1464C13.0488 12.3417 13.0488 12.6583 12.8536 12.8536C12.6583 13.0488 12.3417 13.0488 12.1464 12.8536L9.30884 10.0159Z"
            fill="currentColor"
            fillRule="evenodd"
            clipRule="evenodd"
          ></path>
        </svg>
        <Input
          type="search"
          readOnly
          placeholder="Search..."
          className="w-full rounded-lg bg-background pl-9 md:w-[200px] lg:w-[150px]"
        />
      </div>
    </NavLink>
  );
};

import { Tabs, TabsContent, TabsList, TabsTrigger } from "#app/components/ui/tabs";
import { Payment, SkeltonList } from "#app/routes/resources+/finance";
import { ClientOnly } from "remix-utils/client-only";
import { ViewportProvider } from "#app/components/client/viewport.client";

const classTabTrigger =
  "bg-[#EAE9E9] text-[#302B2B] rounded-lg data-[state=active]:bg-[#D7B9FD] data-[state=active]:text-[#45008E]";
import { BellRing, Check } from "lucide-react";

import {
  Card,
  CardContent,
  CardDescription,
  CardFooter,
  CardHeader,
  CardTitle,
} from "#app/components/ui/card";
import { Switch } from "#app/components/ui/switch";

const notifications = [
  {
    title: "You have a new message!",
    description: "1 hour ago",
  },
  {
    title: "You have a new message!",
    description: "1 hour ago",
  },
  {
    title: "You have a new message!",
    description: "1 hour ago",
  },
];

type CardProps = React.ComponentProps<typeof Card>;

function CardDemo({ className, ...props }: CardProps) {
  const [unreadNotifications, setUnreadNotifications] = React.useState(null); // Misalnya ada 3 notifikasi belum terbaca

  let notificationContent;
  if (unreadNotifications > 0) {
    notificationContent = `You have ${unreadNotifications} unread notifications.`;
  } else if (unreadNotifications === 0 && unreadNotifications !== null) {
    notificationContent = "All your notifications are read.";
  } else {
    notificationContent = "No notifications at the moment.";
  }
  return (
    <Card className={cn("rounded-sm w-[380px]", className)} {...props}>
      <CardHeader className="p-4">
        <CardTitle>Notifications</CardTitle>
        <CardDescription>{notificationContent}</CardDescription>
      </CardHeader>
      <CardContent className="grid gap-4 pl-5 pr-3">
        <div>
          {notifications.map((notification, index) => (
            <div
              key={index}
              className="mb-4 grid grid-cols-[25px_1fr] items-start pb-4 last:mb-0 last:pb-0"
            >
              <span className="flex h-2 w-2 translate-y-1 rounded-full bg-sky-500" />
              <div className="space-y-1">
                <p className="text-sm font-medium leading-none">{notification.title}</p>
                <p className="text-sm text-muted-foreground">{notification.description}</p>
              </div>
            </div>
          ))}
        </div>
      </CardContent>
      {/*<CardFooter>
        <Button className="w-full">
          <Check /> Mark all as read
        </Button>
      </CardFooter>*/}
    </Card>
  );
}

// Dummy Data Notifikasi (Biasanya dari API)
const dummyNotifications = [
  // {
  //   id: 1,
  //   user: "Jacob Thompson",
  //   message: "has joined your group",
  //   time: "1 day ago",
  //   read: false,
  //   avatar: "/static/images/logokedi.svg",
  // },
  // {
  //   id: 2,
  //   user: "Rizky Hasanuddin",
  //   message: "sent you a private message",
  //   time: "1 day ago",
  //   read: true,
  //   avatar: "/static/images/logokedi.svg",
  // },
  // {
  //   id: 3,
  //   user: "Alice Johnson",
  //   message: "commented on your post",
  //   time: "3 hours ago",
  //   read: false,
  //   avatar: "/static/images/logokedi.svg",
  // },
];

function Notifications() {
  const [notifications, setNotifications] = React.useState([]);
  const [isLoading, setIsLoading] = React.useState(false);

  // Simulasikan pengambilan data dari API
  React.useEffect(() => {
    setIsLoading(true);
    // Simulate API call
    setTimeout(() => {
      setNotifications(dummyNotifications);
      setIsLoading(false);
    }, 1000);
  }, []);

  // Fungsi untuk menandai semua notifikasi sebagai sudah dibaca
  const markAllAsRead = () => {
    setNotifications((prevNotifications) =>
      prevNotifications.map((notif) => ({ ...notif, read: true }))
    );
  };

  // Fungsi untuk menangani klik pada notifikasi (menandai sebagai sudah dibaca)
  const handleMarkAsRead = (id) => {
    setNotifications((prevNotifications) =>
      prevNotifications.map((notif) => (notif.id === id ? { ...notif, read: true } : notif))
    );
  };

  return (
    <main className="xs:mx-3 bg-white lg:rounded-xl w-[350px] divide-y">
      <div className="flex justify-between px-4 py-3">
        <div className="flex gap-x-1.5">
          <h1 className="font-bold">Notifications</h1>
          {notifications.length > 0 && (
            <Badge variant="secondary" className="rounded-full">
              {notifications.filter((notif) => !notif.read).length}
            </Badge>
          )}
        </div>
        {notifications.length > 0 && (
          <span
            id="mark-all-as-read"
            className="flex items-center gap-x-1 text-sm font-bold text-muted-foreground cursor-pointer hover:text-primary"
            onClick={markAllAsRead}
          >
            <svg
              width="15"
              height="15"
              viewBox="0 0 15 15"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M7.49991 0.877045C3.84222 0.877045 0.877075 3.84219 0.877075 7.49988C0.877075 11.1575 3.84222 14.1227 7.49991 14.1227C11.1576 14.1227 14.1227 11.1575 14.1227 7.49988C14.1227 3.84219 11.1576 0.877045 7.49991 0.877045ZM1.82708 7.49988C1.82708 4.36686 4.36689 1.82704 7.49991 1.82704C10.6329 1.82704 13.1727 4.36686 13.1727 7.49988C13.1727 10.6329 10.6329 13.1727 7.49991 13.1727C4.36689 13.1727 1.82708 10.6329 1.82708 7.49988ZM10.1589 5.53774C10.3178 5.31191 10.2636 5.00001 10.0378 4.84109C9.81194 4.68217 9.50004 4.73642 9.34112 4.96225L6.51977 8.97154L5.35681 7.78706C5.16334 7.59002 4.84677 7.58711 4.64973 7.78058C4.45268 7.97404 4.44978 8.29061 4.64325 8.48765L6.22658 10.1003C6.33054 10.2062 6.47617 10.2604 6.62407 10.2483C6.77197 10.2363 6.90686 10.1591 6.99226 10.0377L10.1589 5.53774Z"
                fill="currentColor"
                fillRule="evenodd"
                clipRule="evenodd"
              ></path>
            </svg>
            <span>Mark all as read</span>
          </span>
        )}
      </div>

      {isLoading ? (
        <div
          role="status"
          className="max-w-md p-3 space-y-4  divide-y divide-gray-200 rounded  animate-pulse dark:divide-gray-700 md:p-6 dark:border-gray-700"
        >
          <div className="flex items-center justify-between">
            <div>
              <div className="h-2.5 bg-gray-300 rounded-full dark:bg-gray-600 w-24 mb-2.5" />
              <div className="w-32 h-2 bg-gray-200 rounded-full dark:bg-gray-700" />
            </div>
            <div className="h-2.5 bg-gray-300 rounded-full dark:bg-gray-700 w-12" />
          </div>
          <div className="flex items-center justify-between pt-4">
            <div>
              <div className="h-2.5 bg-gray-300 rounded-full dark:bg-gray-600 w-24 mb-2.5" />
              <div className="w-32 h-2 bg-gray-200 rounded-full dark:bg-gray-700" />
            </div>
            <div className="h-2.5 bg-gray-300 rounded-full dark:bg-gray-700 w-12" />
          </div>
          <div className="flex items-center justify-between pt-4">
            <div>
              <div className="h-2.5 bg-gray-300 rounded-full dark:bg-gray-600 w-24 mb-2.5" />
              <div className="w-32 h-2 bg-gray-200 rounded-full dark:bg-gray-700" />
            </div>
            <div className="h-2.5 bg-gray-300 rounded-full dark:bg-gray-700 w-12" />
          </div>
          <span className="sr-only">Loading...</span>
        </div>
      ) : notifications?.length > 0 ? (
        notifications.map((notif) => (
          <div
            key={notif.id}
            className={`flex justify-between p-3 ${notif.read ? "bg-background" : "notification-unread"}`}
          >
            <Avatar className="h-8 w-8 rounded-md">
              <AvatarImage
                className="rounded-md"
                src={notif.avatar ? notif.avatar : "/static/images/logokedi.svg"}
                alt={notif?.user}
              />
              <AvatarFallback className="rounded-md">{getInitials(notif.user)}</AvatarFallback>
            </Avatar>
            <div className="ml-2 text-xs flex-auto">
              <a
                href="#"
                className={`font-bold ${notif.read ? "" : ""}`}
                onClick={() => handleMarkAsRead(notif.id)}
              >
                {notif.user}
              </a>
              <span className="text-muted-foreground ml-2 text-xs">{notif.message}</span>
              <p className="text-muted-foreground mt-1 text-xs">{notif.time}</p>
            </div>
          </div>
        ))
      ) : (
        <div className="flex items-center justify-center text-gray-600  h-[95%]">
          <EmptyState icon={<Icon name="notifications" />} title="No notification display" />
        </div>
      )}
    </main>
  );
}

const NotifIcon = () => {
  const [value, setValue] = React.useState("contacts");
  const { user } = useRouteLoaderData<typeof RootLoader>("root") || {};
  const notifMessage: [] | null = null;

  return (
    <DropdownMenu>
      <DropdownMenuTrigger className="rounded-lg relative outline-none" asChild>
        <Button variant="ghost" className="px-2.5 rounded-md">
          {notifMessage?.filter((d: any) => d.publisher !== user?.uuid).length > 0 && (
            <span className="absolute py-0.5 px-1.5 bg-green-400 rounded-full -top-1 right-1 border-2 border-white text-sm font-medium">
              {notifMessage?.filter((d) => d.publisher !== user?.uuid).length}
            </span>
          )}
          <BellRing className="w-5 h-5 text-muted-foreground" />
        </Button>
      </DropdownMenuTrigger>
      <DropdownMenuContent className="border-none mr-3">
        <Notifications />
        {/*<CardDemo />*/}
        {/*<Tabs value={value} onValueChange={setValue}>
          <TabsList className="">
            <TabsTrigger value="class">Notifikasi</TabsTrigger>
            <TabsTrigger value="contacts">Status Transaksi</TabsTrigger>
          </TabsList>
          <TabsContent
            forceMount
            hidden={value !== "class"}
            value="class"
            className="h-[60vh] overflow-y-auto px-1.5 space-y-2 pt-1.5 border rounded-lg mx-2 mb-2 mt-3 shadow-sm"
          >
            <div className="grid h-full place-content-center px-4">
              <div className="text-center">
                <h1 className="text-6xl font-black text-gray-200">Progress</h1>

                <p className="mt-4 text-gray-500">this menu is on proggres</p>
              </div>
            </div>
          </TabsContent>
          <TabsContent
            forceMount
            hidden={value !== "contacts"}
            value="contacts"
            className="px-1.5 pb-4"
          >
            <ClientOnly fallback={<SkeltonList />}>
              {() => (
                <ViewportProvider>
                  <Payment isNotif={true} />
                </ViewportProvider>
              )}
            </ClientOnly>
          </TabsContent>
        </Tabs>*/}
      </DropdownMenuContent>
    </DropdownMenu>
  );
};

export const Navbar = () => {
  return (
    <header>
      <div id="debug-development" />
      <nav className="fixed top-0 z-20 bg-white h-[65px]  items-center w-full grid grid-cols-2 md:grid-cols-3 gap-5  px-2.5 sm:px-4 border-b shadow-md pb-0.5">
        <Logo />
        <NavigationMenu className="hidden md:flex items-center justify-center gap-x-3 lg:gap-x-5" />
        <div className="flex items-center justify-end gap-x-5">
          <div className="flex items-center gap-x-3">
            <SearchMenu />
            <NotifIcon />
            <UserNav />
          </div>
        </div>
      </nav>
      <nav className="md:hidden fixed bottom-0 left-0 z-50 w-full h-[60px] bg-white border-t border-gray-200 ">
        <NavigationMenu className="grid h-full max-w-lg grid-cols-5 mx-auto font-medium pt-1.5" />
      </nav>
      <div className="h-[70px]" />
    </header>
  );
};

import { Avatar, AvatarFallback, AvatarImage } from "#app/components/ui/avatar";

function UserNav() {
  const { user } = useRouteLoaderData<typeof RootLoader>("root") || {};

  const { i18n } = useTranslation();
  const fetcher = useFetcher();

  const onValueChange = (lang: string) => {
    i18n.changeLanguage(lang);
    fetcher.submit(null, {
      method: "POST",
      action: `/settings/change-language/${lang}`,
    });
  };

  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>
        <Button variant="ghost" className="relative h-8 w-8 rounded-md">
          <Avatar className="h-8 w-8 rounded-md">
            <AvatarImage
              className="rounded-md object-cover"
              src={user?.photo ? user?.photo : "/static/images/logokedi.svg"}
              alt={user?.fullName}
            />
            <AvatarFallback className="rounded-md">{getInitials(user?.fullName)}</AvatarFallback>
          </Avatar>
        </Button>
      </DropdownMenuTrigger>
      <DropdownMenuContent className="w-56" align="end" forceMount>
        <DropdownMenuLabel className="font-normal flex items-center gap-x-2">
          <Avatar className="h-8 w-8">
            <AvatarImage
              className="object-cover"
              src={user?.photo ? user?.photo : "/static/images/logokedi.svg"}
              alt={user?.fullName}
            />
            <AvatarFallback>{getInitials(user?.fullName)}</AvatarFallback>
          </Avatar>
          <div className="flex flex-col space-y-1">
            <p className="text-sm font-medium leading-none">{user?.fullName}</p>
            <p className="text-xs leading-none text-muted-foreground max-w-40 truncate">
              {user?.email}
            </p>
          </div>
        </DropdownMenuLabel>
        <DropdownMenuSeparator />
        <DropdownMenuGroup>
          <DropdownMenuItem asChild>
            <Form
              method="get"
              action={`/profile/${user?.id}`}
              className="flex w-full items-center gap-1.5 rounded-md p-2 text-sm hover:bg-purple-100"
              role="menuitem"
            >
              <button type="submit" className="flex w-full items-center gap-1.5 rounded-md text-sm">
                <Icon name="avatar" size="md">
                  Profile
                </Icon>
              </button>
            </Form>
          </DropdownMenuItem>
          <DropdownMenuSub>
            <DropdownMenuSubTrigger>
              <Icon name="language" size="md" className="sm:flex hidden text-gray-600">
                Bahasa
              </Icon>
            </DropdownMenuSubTrigger>
            <DropdownMenuSubContent>
              <DropdownMenuRadioGroup value={i18n.language} onValueChange={onValueChange}>
                <DropdownMenuRadioItem value="id">Indonesia</DropdownMenuRadioItem>
                <DropdownMenuRadioItem value="en">English</DropdownMenuRadioItem>
              </DropdownMenuRadioGroup>
            </DropdownMenuSubContent>
          </DropdownMenuSub>
        </DropdownMenuGroup>
        <DropdownMenuSeparator />
        <DropdownMenuItem asChild>
          <Form
            method="post"
            action={`/logout`}
            className="flex w-full items-center gap-1.5 rounded-md p-2 text-sm hover:bg-purple-100"
            role="menuitem"
          >
            <button type="submit" className="flex w-full items-center gap-1.5 rounded-md text-sm">
              <Icon name="logout" size="md">
                Log Out
              </Icon>
            </button>
          </Form>
        </DropdownMenuItem>
      </DropdownMenuContent>
    </DropdownMenu>
  );
}
